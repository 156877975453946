import React from "react";

import python from "../assets/python.png";
import aws from "../assets/aws.png";
import javascript from "../assets/javascript.png";
import gcp from "../assets/gcp.png";
import pulumi from "../assets/pulumi.png";
import sql from "../assets/sql.png";
import github from "../assets/github.png";
import power from "../assets/power-bi.svg";
import node from "../assets/node.png";

const Skills = () => {
  const techs = [
    {
      id: 1,
      src: python,
      title: "Python",
      style: "shadow-blue-500",
    },
    {
      id: 2,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
        id: 3,
        src: aws,
        title: "Amazon Web Services",
        style: "shadow-yellow-100",
      },
      {
        id: 4,
        src: gcp,
        title: "Google Cloud Platform",
        style: "shadow-red-500",
      },
      {
        id: 5,
        src: pulumi,
        title: "Pulumi",
        style: "shadow-violet-500",
      },
      {
        id: 6,
        src: sql,
        title: "SQL",
        style: "shadow-blue-500",
      },
      {
        id: 7,
        src: power,
        title: "PowerBI",
        style: "shadow-yellow-800",
      },
      {
        id: 8,
        src: node,
        title: "NodeJS",
        style: "shadow-green-500",
      },
    {
      id: 9,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
    
  ];

  return (
    <div
      name="skills"
      className="bg-gradient-to-b from-gray-800 to-black w-full h-screen"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Skills
          </p>
          <p className="py-6">These are some of the Technologies I worked with, To get brief details refer to my resume</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;